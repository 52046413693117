<template>
    <div class="service-module">
<!--        标题-->
        <div class="uf uf-ac pr lh2 pt-2 pb-2 border-bottom">
            <div class="" style="margin: 0 auto;">服务模块</div>
            <div class="uf uf-ac message-list cp" @click="$router.push('chat-list')">
<!--                <el-badge :value="12" class="item" hidden>-->
<!--                    <i class="el-icon-chat-dot-square" style="font-size: 20px"></i>-->
<!--                </el-badge>-->
                <i class="el-icon-chat-dot-square" style="font-size: 20px"></i>
                <div class="ml-1 f12">消息列表</div>
            </div>
        </div>
<!--      模块选择  -->
        <div class="uf uf-ac uf-pj fwrap-w p-2">
            <div class="uf uf-ac uf-pc mt-2 wi30 qbtn"  v-for="item in serviceList" :key="item.dictId" >
                <div class="uf uf-column"  @click="expertConsult(item)">
                    <i class="iconfont" :class="item.icon" style="font-size: 30px"></i>
                    <div class="mt-2 f14" >{{item.dictName}}</div>
                </div>
            </div>
            <div class="wi30 hidden"></div>
            <div class="wi30 hidden "></div>
            <div class="wi30 hidden "></div>
            <div class="wi30 hidden "></div>
        </div>
    </div>
    
</template>

<script>
    import {checkIsNull} from '../../utils'
    export default {
        name: 'service-module',
        data () {
            return {
                serviceList: [
                    {
                        dictId: '1',
                        dictName: '检验检测服务',
                        // icon: 'icon-zhilianganquan'
                        icon: 'icon-jiance'
                    },
                    {
                        dictId: '2',
                        dictName: '标准制定服务',
                        icon: 'icon-zu'
                    },
                    {
                        dictId: '3',
                        dictName: '质量管理服务',
                        icon: 'icon-zhiliang'
                    },
                    {
                        dictId: '4',
                        dictName: '产品安全服务',
                        icon: 'icon-anquan'
                    },
                    {
                        dictId: '5',
                        dictName: '信用评估服务',
                        icon: 'icon-chengxinxi'
                    },
                    {
                        dictId: '6',
                        dictName: '品牌评价服务',
                        icon: 'icon-medal'
                    },
                    {
                        dictId: '7',
                        dictName: '宏观质量诊断',
                        icon: 'icon-docs'
                    },
                    {
                        dictId: '8',
                        dictName: '知产工商服务',
                        icon: 'icon-zhishichanquan'
                    }
                ]
            }
        },
        computed: {
            loginStatus: {
                get () { return this.$store.state.user.loginStatus },
                set (val) { this.$store.commit('user/updateLoginStatus', val) }
            },
            userId: {
                get () { return this.$store.state.user.id },
                set (val) { this.$store.commit('user/updateId', val) }
            },
            name: {
                get () { return this.$store.state.user.name },
                set (val) { this.$store.commit('user/updateName', val) }
            },
            email: {
                get () { return this.$store.state.user.email },
                set (val) { this.$store.commit('user/updateEmail', val) }
            },
            userName: {
                get () { return this.$store.state.user.userName },
                set (val) { this.$store.commit('user/updateUserName', val) }
            },
            mobile: {
                get () { return this.$store.state.user.mobile },
                set (val) { this.$store.commit('user/updateMobile', val) }
            },
            orgCode: {
                get () { return this.$store.state.user.orgCode },
                set (val) { this.$store.commit('user/updateOrgCode', val) }
            },
            orgName: {
                get () { return this.$store.state.user.orgName },
                set (val) { this.$store.commit('user/updateOrgName', val) }
            },
            accountType: {
                get () { return this.$store.state.user.accountType },
                set (val) { this.$store.commit('user/updateAccountType', val) }
            },
            info: {
                get () { return this.$store.state.user.info },
                set (val) { this.$store.commit('user/updateInfo', val) }
            },
            unRead: {
                get () { return this.$store.state.user.unRead },
                set (val) { this.$store.commit('user/updateUnRead', val) }
            },
            registerTime: {
                get () { return this.$store.state.user.registerTime },
                set (val) { this.$store.commit('user/updateRegisterTime', val) }
            },
            userDiscountEntityList: {
                get () { return this.$store.state.user.userDiscountEntityList },
                set (val) { this.$store.commit('user/updateUserDiscountEntityList', val) }
            },
            userHead: {
                get () { return this.$store.state.user.userHead },
                set (val) { this.$store.commit('user/updateUserHead', val) }
            }
        },
        created () {
            // 根据token判断是否登录中
            const token = this.$cookie.get('marketToken')
            if (!checkIsNull(token)) {
                this.loginStatus = true
            }
        },
        mounted () {
            // 如果已登录--重新获取当前登录用户信息
            if (this.loginStatus) {
                this.getUserInfo()
            } else {
                this.contentVisible = true
            }
        },
        methods: {
            // 获取当前登录用户信息
            getUserInfo () {
                this.contentVisible = false
                this.$http({
                    url: this.$http.adornUrl(this.$api.SYS.USER.INFO),
                    method: 'get'
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.userId = data.user.userId
                        this.name = data.user.name
                        this.userName = data.user.username
                        this.email = data.user.email
                        this.mobile = data.user.mobile
                        this.orgCode = data.user.orgCode
                        this.orgName = data.user.orgName
                        this.accountType = data.user.accountType
                        this.registerTime = data.user.createTime
                        this.unRead = data.user.unRead
                        if (data.user.headPath) {
                            this.userHead = data.user.headPath
                        }
                        // this.info = data.user.userInfo || []
                        this.userDiscountEntityList = data.user.userDiscountEntityList || []
                    }
                    this.contentVisible = true
                })
            },
        //  选择模块专家
            expertConsult (item) {
                this.$confirm('请选择咨询类型?', '提示', {
                    confirmButtonText: '人工诊断',
                    cancelButtonText: '智能诊断',
                    type: 'warning',
                    customClass: 'myClass'
                }).then(() => {
                    this.$http({
                        url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.RANDOM_DOCTOR),
                        method: 'GET',
                        params: this.$http.adornParams({
                            industryScope: item.dictId
                        })
                    }).then(({data}) => {
                        if (data && data.code === 0) {
                            let exportInfo = data.doctor || {}
                            if (exportInfo && exportInfo.doctorId) {
                                this.$nextTick(() => {
                                    this.$router.push({name: 'chat-user', query: {
                                            doctorInfo: exportInfo.sysUserEntity
                                        }})
                                })
                            } else {
                                this.$message.warning('该模块当前暂无在线专家，请稍后咨询')
                            }
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                    // this.chatBoxVisiable = true
                    // this.$nextTick(() => {
                    //   this.$refs.chatBoxRef.init(exportInfo, false)
                    // })
                }).catch(() => {
                    //  智能诊断
                })
            }
        }
    }
</script>

<style scoped>
    .service-module{
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: #fff;
    }
    .message-list {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        text-align: center;
    }
    .hidden {
        height: 1px;
        visibility: hidden;
        /*margin: 0;*/
        padding: 0 10px;
    }
    .service-module .qbtn {
        /*margin: 20px;*/
        height: 100px;
        text-align: center;
        padding: 15px;
        background: linear-gradient(30deg, #69FF97, #00E4FF);
        border-radius: 10px;
        color: #FFFFFF;
        box-shadow: 0 0 12px rgba(2,70,172,.3);
        cursor: pointer;
        transition: all .3s ease;
    }
    .quick-btn {
        position: relative;
        z-index: 999;
    }
    .qbtn > div {
        font-size: 1vw;
    }
    /*.service-module .qbtn:hover {*/
    /*    transform: scale(1.1);*/
    /*}*/
    /*.service-module .qbtn:nth-child(2) {*/
    /*    background: linear-gradient(30deg, #c509fd, #b28eff);*/
    /*    box-shadow: 0 0 26px rgba(105, 0, 140, 0.3);*/
    /*}*/
    /*.service-module .qbtn:nth-child(3) {*/
    /*    background: linear-gradient(30deg, #69FF97, #00E4FF);*/
    /*    box-shadow: 0 0 26px rgba(0, 138, 100, 0.3);*/
    /*}*/
    /*.service-module .qbtn:nth-child(4) {*/
    /*    background: linear-gradient(30deg, #FFE985, #FA742B);*/
    /*    box-shadow: 0 0 26px rgba(138, 27, 0, 0.3);*/
    /*}*/
    /*.service-module .qbtn:nth-child(5) {*/
    /*    background: linear-gradient(30deg, #00c3e3, #af9aff);*/
    /*    box-shadow: 0 0 26px rgba(0, 108, 138, 0.3);*/
    /*}*/
    /*.service-module .qbtn:nth-child(6) {*/
    /*    background: linear-gradient(30deg, #ff005e, #ff6ab6);*/
    /*    box-shadow: 0 0 26px rgba(138, 0, 34, 0.3);*/
    /*}*/
    /*.service-module .qbtn:nth-child(7) {*/
    /*    background: linear-gradient(30deg, #a56ee3, #008cff);*/
    /*    box-shadow: 0 0 26px rgba(0, 108, 138, 0.3);*/
    /*}*/
    /*.service-module .qbtn:nth-child(8) {*/
    /*    background: linear-gradient(30deg, #00ffd0, #00a6ff);*/
    /*    box-shadow: 0 0 26px rgba(0, 73, 138, 0.3);*/
    /*}*/
</style>
<style>
    .myClass {
        width: 350px;
    }
</style>